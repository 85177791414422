var app;
(function (app) {
    var core;
    (function (core) {
        var config;
        (function (config_1) {
            'use strict';
            var AppRunConfig = /** @class */ (function () {
                /* @ngInject */
                AppRunConfig.$inject = ["dataservice", "mylocalStore", "$rootScope", "rxData", "$timeout", "errorHandler"];
                function AppRunConfig(dataservice, mylocalStore, $rootScope, rxData, $timeout, errorHandler) {
                    this.dataservice = dataservice;
                    this.mylocalStore = mylocalStore;
                    this.$rootScope = $rootScope;
                    this.rxData = rxData;
                    this.$timeout = $timeout;
                    this.errorHandler = errorHandler;
                }
                /// declare application global variables here, and run callback to start initialization
                AppRunConfig.prototype.init = function (config, callback) {
                    if (config !== undefined) {
                        if (!_.isObject(config)) {
                            this.errorHandler.displayError({ error: "cannot run AppRunConfig.init, config params are not an object" });
                            console.log('cannot run AppRunConfig.init, config params are not an object');
                            return null;
                        }
                        var validKeys_1 = ['mylocalStore', 'setTitleLang', 'cacheImages'];
                        var validConfig = Object.keys(config).map(function (val) {
                            return validKeys_1.indexOf(val) === -1;
                        });
                        if (validConfig.indexOf(true) !== -1) {
                            this.errorHandler.displayError({ error: "cannot run AppRunConfig.init, config params have invalid keys!!!" });
                            console.log('cannot run AppRunConfig.init, config params have invalid keys!!!');
                            return null;
                        }
                        // GLOBALS
                        this.$rootScope.IPATH = "./dist/styles/images";
                        // preload images      
                        config.cacheImages.cacheIt(['pressWeb-AboutUS@2x.jpg', 'pressWeb-career@2x.jpg', 'pressWeb-Media@2x.jpg', 'contact-map-contacts@2x.jpg']);
                        /// SET LANG
                        LANG_TARGET = LANG_TARGET || config.mylocalStore.setTitleLanguage('ENG');
                        this.$rootScope.staticVars = { nav: { blog: config.setTitleLang('blog') } };
                        this.$rootScope.LANG_TARGET = LANG_TARGET;
                        // important variable load our object data for each page after it has been parsed by rx.js data service  
                        this.$rootScope.CURRENT_PAGE_DATA = this.$rootScope.CURRENT_PAGE_DATA || null;
                        this.$rootScope.gotCareerJobs = this.$rootScope.gotCareerJobs || false;
                        this.$rootScope.JobsData = this.$rootScope.JobsData || null;
                        this.$rootScope.ALL_DATA = this.$rootScope.ALL_DATA || { 'PAGES': null };
                        this.$rootScope.heroReady = false;
                        this.$rootScope._ = window._;
                        this.$rootScope.angularLoader = 0;
                        this.$rootScope.showLayout = 0;
                        this.$rootScope.rxObserverObject = { delivered: false };
                        this.$rootScope.angular_loaded_once = this.$rootScope.angular_loaded_once || angular_loaded_once;
                        this.$rootScope.heroLoaded = 0; // it is initialized on every state, and reset here
                        this.rxData.init(); // to resolve a promise on initial call
                        this.$rootScope.rxObserver = this.rxData.initObserver(); // rxObserver
                        /// just check our props
                        var rootScopeProps = [];
                        for (var key in this.$rootScope) {
                            if (this.$rootScope.hasOwnProperty(key) && key.indexOf('$') === -1) {
                                rootScopeProps.push(key);
                            }
                        }
                        ///////////////////////////////////
                        /// run our app config login here             
                        callback(rootScopeProps);
                        ///////////////////////////////////
                    }
                    else {
                        this.errorHandler.displayError({ error: "AppRunConfig.config dependancies not loaded!!" });
                        console.log('AppRunConfig.config dependancies not loaded!!');
                        return;
                    }
                };
                /**
                 * TO CHECK if our current page has hero.html or not
                 *
                 */
                AppRunConfig.prototype.layoutHasHero = function (state) {
                    /// these pages do not have hero, so animation delay is ommited
                    var has_no_hero = ['contacts', 'timeline', 'home'];
                    var has_hero = true;
                    for (var i = 0; i < has_no_hero.length; i++) {
                        if (has_no_hero[i] == state) {
                            has_hero = false;
                            break;
                        }
                    }
                    if (has_hero) {
                        // console.log('state has hero', state)
                    }
                    else {
                        //console.log('state no!! hero', state)
                    }
                    return has_hero;
                };
                /**
                *  we generate menu output object then munipulate the output
                * on stateChangeStart
                */
                AppRunConfig.prototype.generateMenu = function (getstates) {
                    var pages = getstates();
                    // lets merge our subpages dynamicly to create sublisting in nav.
                    var _pages = pages.concat(subPages());
                    /// remove conflict state master
                    _pages.filter(function (val, i) {
                        if (val.config.master !== undefined) {
                            _pages.splice(i, 1); // remove master as we have added subs 
                        }
                    });
                    var _final = [];
                    // let deferred = defer.defer();
                    if (_.isArray(_pages) == false)
                        return false;
                    // go thru process logic and add sub pages by "rel" and append to "page.sub"
                    var output = _pages.reduce(function (result, page) {
                        result = result || [];
                        if (page.state) {
                            result.push({ title: page.config.title, state: page.state, url: page.config.url, rel: page.config.rel || false, main: page.config.main });
                        }
                        return result;
                    }, []).reduce(function (f, page, i, arr) {
                        if (page.rel) {
                            for (var n = 0; n < arr.length; n++) {
                                if (page.rel === arr[n].state) {
                                    page.sub = (page.sub !== undefined) ? page.sub : [];
                                    page.sub.push({
                                        title: arr[n].title,
                                        state: arr[n].state,
                                        url: arr[n].url,
                                        main: arr[n].main
                                    });
                                }
                            }
                        }
                        _final.push(page);
                        return _final;
                    }, []);
                    //// make sub page format
                    function subPages(lang) {
                        // we have to make translation of menu items here, cannot target from http request, due to lag time
                        // since .run get initiatied instantly (without lag) /// done
                        if (lang === void 0) { lang = "ENG"; }
                        //// the login for booth is the same unlet we want to change the language on url's itself
                        var galleryPages;
                        if (lang == "ENG") {
                            galleryPages = [{ slug: 'thailand', title: 'Thailand' }, { slug: 'malaysia', title: 'Malaysia' }, { slug: 'people', title: 'People' }];
                        }
                        if (lang == "TH") {
                            galleryPages = [{ slug: 'thailand', title: 'Thailand' }, { slug: 'malaysia', title: 'Malaysia' }, { slug: 'people', title: 'People' }];
                        }
                        /// merge new subpages
                        var _supPages = []; //Object.assign()  
                        for (var p = 0; p < galleryPages.length; p++) {
                            _supPages.push({
                                state: 'gallery',
                                config: {
                                    main: 'media',
                                    url: "/app/media-gallery/" + galleryPages[p].slug,
                                    template: '<gallery></gallery>',
                                    title: galleryPages[p].title
                                }
                            });
                        }
                        return _supPages || null;
                    }
                    return output;
                };
                /**
                   * on here we add the current state .active to $rootScope.pages object that is called from layout.html
                   *
                   * then we create a var payload; object which is passed to dataservice.getDATA on ready
                   * * on local server it would be fine to call onNext from stateChangeStart  because it already has  getDATA available, but on live site it will be  async and not in order, hence this sollution works.
                   */
                /// initial state when loading
                /////////////////////////////////////////////////////////////////////////// 
                AppRunConfig.prototype.stateChangeStart = function (state) {
                    var _this = this;
                    if (this.$rootScope.rxObserver === undefined) {
                        this.errorHandler.displayError({ error: "cannot run AppRunConfig.stateChangeStart, rxObserver not available" });
                        console.log('cannot run AppRunConfig.stateChangeStart, rxObserver not available');
                        return null;
                    }
                    this.$rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
                        _this.$rootScope.heroLoaded = 0;
                        _this.$rootScope.angularLoader = 0;
                        _this.$rootScope.showLayout = 0;
                        /// init constructor, we need to reinitiate constractor to reset our promise to new
                        _this.rxData.init();
                        //// always scrall to top
                        $(window).scrollTop(0);
                        _this.$timeout(function () {
                            // delay loading hero section to avoid image flickering
                            _this.$rootScope.heroReady = true;
                        }, 600);
                        // add top level pages dynamicly
                        var current_page_nav_data = '';
                        _this.$rootScope.pages.forEach(function (page, i) {
                            _this.$timeout(function () {
                                //  console.log('$rootScope.pages[i]', $rootScope.pages[i])
                                if (_this.$rootScope.pages[i].state === state.current.name) {
                                    _this.$rootScope.pages[i].active = true;
                                    current_page_nav_data = _this.$rootScope.pages[i];
                                }
                                else {
                                    delete _this.$rootScope.pages[i].active;
                                }
                                // console.log('$rootScope.pages', $rootScope.pages)
                            }, 100);
                        });
                        _this.$timeout(function () {
                            console.log('$state.current', state);
                            _this.$rootScope.pageName = state.current.name + '-state';
                            _this.$rootScope.currentState = state.current.name;
                            _this.$rootScope.pageOrgName = false;
                            if (state.params.gallery !== undefined) {
                                _this.$rootScope.pageOrgName = state.params.gallery;
                            }
                            // check if sub page has parent from previous available data
                            var parent_page = {};
                            if (current_page_nav_data.main !== undefined) {
                                parent_page.main = current_page_nav_data.main;
                                parent_page.rel = current_page_nav_data.state;
                            }
                            else {
                                parent_page = false;
                            }
                            var payload = { "type": "initialize_data", "message": "data for " + _this.$rootScope.currentState, stateName: _this.$rootScope.currentState, errorFeed: false, has_sub: parent_page, pageOrgName: _this.$rootScope.pageOrgName };
                            console.log('$rootScope.currentState', _this.$rootScope.currentState);
                            _this.$rootScope.rxObserverObject = Object.assign(_this.$rootScope.rxObserverObject, payload);
                            if (_this.$rootScope.rxObserverObject.delivered == false) {
                                _this.$rootScope.rxObserver.onNext(_this.$rootScope.rxObserverObject);
                                console.log('-- calling rxObserver from stateChangeStart');
                            }
                            console.log('parent_page ', parent_page);
                        }, 101);
                    });
                };
                ///////////////////////////////////////////////////
                /**
                 * UX/UI
                 * heroLoaded & showLayout  are used to show transition between the states/pages
                 */
                // finished state
                AppRunConfig.prototype.stateChangeSuccess = function () {
                    var _this = this;
                    this.$rootScope.$on("$stateChangeSuccess", function (e, toState, toParams, fromState, fromParams) {
                        /// remove nav if open from previous state.
                        $('body').find("#navbarCollapse").removeClass('show');
                        /**
                         * angular animation loading transition mechanism
                         * if angular loaded for the first time we do not want to show the whole page
                         * angular_loaded_once < using this var to delegate
                         *
                         * there after on every state transition we use "$rootScope.angularLoader"
                         * to show nice preloading animation :)
                         *
                         */
                        //  $rootScope.heroLoaded
                        _this.$timeout(function () {
                            if (_this.$rootScope.angular_loaded_once == 0) {
                                angular_loaded_once = 1;
                                _this.$rootScope.angularLoader = 1;
                                _this.$rootScope.angular_loaded_once = angular_loaded_once;
                                _this.$rootScope.showLayout = 1;
                            }
                        }, 300);
                        var hasHero = _this.layoutHasHero(toState.name);
                        if (!hasHero) {
                            /// we do not wait for ng-init because there is no hero to load so we prest to 1 so it doesnt wait
                            _this.$rootScope.heroLoaded = 1;
                        }
                        // make loading of state with nice transition
                        /// in theory this is not the best solution, because we are not counting image load countdown
                        // only ng-init > which serves when template is loaded.
                        _this.$timeout(function () {
                            if (_this.$rootScope.heroLoaded == 1) {
                                _this.$rootScope.showLayout = 1;
                            }
                            if (_this.$rootScope.angular_loaded_once == 1) {
                                if (hasHero) {
                                    if (_this.$rootScope.heroLoaded == 1) {
                                        _this.$rootScope.showLayout = 1;
                                    }
                                    // awaiting from hero
                                    //ng-init="$rootScope.heroLoaded=1"
                                }
                            }
                        }, 500);
                        // double check
                        _this.$timeout(function () {
                            if (_this.$rootScope.showLayout == 0 && _this.$rootScope.angular_loaded_once == 1) {
                                _this.$rootScope.showLayout = 1;
                            }
                        }, 550);
                    });
                };
                AppRunConfig.prototype.initDataServices = function () {
                    var _this = this;
                    if (this.$rootScope.rxObserver === undefined) {
                        this.errorHandler.displayError({ error: "cannot run AppRunConfig.initDataservices, rxObserver not available" });
                        console.log('cannot run AppRunConfig.initDataservices, rxObserver not available');
                        return null;
                    }
                    /////////////////////////////////////////////////////
                    /// get jobs data
                    if (!this.$rootScope.gotCareerJobs) {
                        this.dataservice.jobsDATA().then(function (data) {
                            // console.log('jobsDATA is',data)
                            _this.$rootScope.rxObserver.onNext({ "type": "allData", "message": "jobs data for career page", jobsDataJsonp: data.response.jobs, errorFeed: false });
                            _this.$rootScope.rxObserverObject.delivered = false;
                            _this.$rootScope.gotCareerJobs = true;
                        }, function (err) {
                            // $rootScope.rxObserver.onNext({ "type":"allData","message": `jobs data for career page not passed`, errorFeed:true });
                            console.log('jobs data for career page not passed');
                            _this.$rootScope.gotCareerJobs = false;
                        });
                    }
                    /////////////////////////////////////////////////////
                    // retreiving local or origin data
                    //////////////////////////////////////////////
                    this.dataservice.getDATA(LANG_TARGET).then(function (data) {
                        if (data.response !== undefined) {
                            console.log('calling data--');
                            _this.$rootScope.rxObserver.onNext({ "type": "allData", "message": "all data passed", jsonData: data.response, errorFeed: false });
                            if (_this.$rootScope.rxObserverObject.type !== undefined) {
                                if (_this.$rootScope.rxObserverObject.type == 'initialize_data') {
                                    var payload = Object.assign(_this.$rootScope.rxObserverObject, { jsonData: data.response });
                                    _this.$rootScope.rxObserver.onNext(_this.$rootScope.rxObserverObject);
                                    console.log('-- calling rxObserver jobsDATA res');
                                }
                            }
                        }
                        else {
                            console.log('else calling data--');
                            _this.$rootScope.rxObserver.onNext({ "type": "allData", "message": " data NOT passed", errorFeed: true });
                        }
                    }, function (err) {
                        console.log(err);
                    });
                };
                return AppRunConfig;
            }());
            config_1.AppRunConfig = AppRunConfig;
            angular
                .module('app.core.AppRunConfig', []);
            angular
                .module('app.core.AppRunConfig')
                .service('appRunConfig', AppRunConfig);
        })(config = core.config || (core.config = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
